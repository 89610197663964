@font-face {
  font-family: "BernhardModernStdRoman";
  src: url("font/BernhardModernStdRoman.otf") format("opentype");
}
@font-face {
  font-family: "SnellBT-Regular";
  src: url("font/SnellBT-Regular.otf") format("opentype");
}
@font-face {
  font-family: "AlphavilleRegular";
  src: url("font/AlphavilleRegular.otf") format("opentype");
}
@font-face {
  font-family: "NocturneSerifTest";
  src: url("font/NocturneSerifTest-ExtraLight.otf") format("opentype");
  font-weight: 200;
}
@font-face {
  font-family: "NocturneSerifTest";
  src: url("font/NocturneSerifTest-Light.otf") format("opentype");
  font-weight: 300;
}
@font-face {
  font-family: "NocturneSerifTest";
  src: url("font/NocturneSerifTest-Regular.otf") format("opentype");
  font-weight: 400;
}
@font-face {
  font-family: "NocturneSerifTest";
  src: url("font/NocturneSerifTest-Medium.otf") format("opentype");
  font-weight: 500;
}
@font-face {
  font-family: "NocturneSerifTest";
  src: url("font/NocturneSerifTest-SemiBold.otf") format("opentype");
  font-weight: 600;
}
@font-face {
  font-family: "NocturneSerifTest";
  src: url("font/NocturneSerifTest-Bold.otf") format("opentype");
  font-weight: 700;
}
@font-face {
  font-family: "NocturneSerifTest";
  src: url("font/NocturneSerifTest-ExtraBold.otf") format("opentype");
  font-weight: 800;
}
@font-face {
  font-family: "NocturneSerifTest";
  src: url("font/NocturneSerifTest-Black.otf") format("opentype");
  font-weight: 900;
}

body {
  margin: 0;
  font-family: "AlphavilleRegular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently*/
  position: relative;
}

code {
  font-family: "AlphavilleRegular";
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "AlphavilleRegular";
  font-weight: 900 !important;
}
p,
a,
span {
  font-family: "AlphavilleRegular";
}
.position-absolute {
  position: absolute;
}
