.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  display: grid;
  place-items: center;
  height: 100vh;
  width: 100%;
  z-index: 1111;
}
.loaddingimage {
  width: 150px;
  height: 150px;
  object-fit: cover;
}
.loading-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
