a,
p {
  font-family: "BernhardModernStdRoman";
  font-weight: 400;
  font-size: 20px;
}
.mompo-navbar-wrapper {
  display: grid;
  grid-template-columns: 4.5fr 1fr 4.5fr;
  z-index: 10;
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  width: 100%;
}
.mompo-navbar-wrapper .mompo-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mompo-navbar-wrapper .mompo-logo-container .mompo-logo {
  height: 7.5rem;
  padding: 10px 0 0;
  width: auto;
}

.mompo-links-container-one .mompo-nav-container {
  list-style: none;
  display: flex;
  justify-content: right;
  align-items: center;
  margin: 0;
  padding: 0%;
}
.mompo-links-container-two .mompo-nav-container {
  list-style: none;
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 0;
  padding: 0%;
}
.mompo-links-container-one {
  margin: auto 0;
  position: relative;
}
.mompo-links-container-two {
  margin: auto 0;
  position: relative;
}
.mompo-nav-link {
  text-decoration: none;
  color: var(--initial-color);
  transition: all 0.2s ease;
  padding: 10px 20px;
  display: inline-block;
  position: relative;
  font-size: 20px;
  outline: none;
  font-family: "SnellBT-Regular";
}
.mompo-nav-link span {
  padding-left: 10px;
  font-family: "SnellBT-Regular";
  font-weight: 400;
}
.mompo-nav-link:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--hover-color);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
/* .mompo-nav-container:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--initial-color-2);
  z-index: -1;
} */
.mompo-nav-link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom center;
}
.mompo-nav-link.active:after {
  transform: scaleX(1);
  transform-origin: bottom center;
}
.mompo-nav-link:hover {
  color: var(--hover-color);
}
.mompo-nav-link.active {
  color: var(--hover-color);
}

.mompo-hamburder-menu {
  display: none;
}
.mompo-nav-item {
  cursor: pointer;
  width: 130px;
  display: flex;
  justify-content: center;
  text-align: center;
}
.mompo-nav-item.es {
  width: 170px;
}
.labguageSelector .mompo-nav-link {
  /*   background-color: var(--initial-color-2);
 */
  border-radius: 2px;
  width: 100%;
  /*   border: 2px solid var(--initial-color-2);
 */
}
.mompo-links-container-mobile {
  display: none;
}
@media only screen and (max-width: 1111px) {
}
@media only screen and (max-width: 991px) {
  .mompo-navbar-wrapper {
    grid-template-columns: 1rem 1fr 1fr 1rem;
    background-color: white;
  }
  .mompo-navbar-wrapper .mompo-logo-container .mompo-logo {
    height: 5rem;
    padding: 5px 0;
  }
  .mompo-mobile-link {
    display: none;
  }
  .mompo-navbar-wrapper .mompo-logo-container {
    justify-content: flex-start;
    grid-column: 2/3;
  }
  .mompo-hamburder-menu {
    padding: 0 1rem;
    display: flex;
    justify-content: end;
    align-items: center;
    color: var(--initial-color);
    z-index: 9;
  }
  .mompo-links-container-mobile {
    display: block;
    position: absolute;
    width: 100%;
    z-index: -1;
  }
  .mompo-links-container-mobile .mompo-nav-container {
    padding: 0%;
    margin: 0%;
    background-color: white;
  }
  .mompo-links-container-mobile .mompo-nav-container .mompo-nav-item {
    width: 100%;
  }
  .mompo-links-container-mobile {
    transform: translateY(-300px);
    transition: 0.5s ease transform;
  }
  .mompo-links-container-mobile.mobileDisplay {
    transform: translateY(80px);
  }
  .mobile-language-selector {
    display: flex;
    width: 100%;
  }
}
@media only screen and (max-width: 769px) {
  .mompo-navbar-wrapper {
    height: auto;
    position: relative;
    z-index: 9;
  }

  .mompo-logo-container {
    z-index: 9;
  }
  .mompo-links-container {
    width: 100%;
    grid-row: 2/3;
    grid-column: 1/5;
    align-items: center;
    position: absolute;
    top: 0;
    z-index: 8;
    background-color: white;
    transform: translateY(-130%);
    transition: all 0.2s ease;
  }

  .mompo-links-container.mompo-mobile-link {
    transform: translateY(0);
  }
  .mompo-nav-container {
    flex-direction: column;
    padding: 1rem 0;
  }
  .mompo-nav-item {
    padding: 0.5rem 0;
  }
  .mompo-nav-link {
    font-size: 16px;
  }
}
