.mompo-aboutus-wrapper {
  position: relative;
  overflow: hidden;
}
.background-container {
  background-image: url("../images/skynavbar.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 250px;
  display: flex;
  align-items: end;
  justify-content: center;
  position: relative;
}

.background-container h4 {
  font-family: "NocturneSerifTest", serif;
  font-weight: 200;
  color: white;
  font-size: 45px;
  padding: 10px 15px;
  /*   background-color: var(--initial-light-color);
 */
  -webkit-text-stroke: 1px var(--initial-color-2);
  text-shadow: -2px 2px 2px var(--initial-light-color);
  margin: 0px 00px 40px;
  /*   border: 2px solid var(--initial-color-3);
 */
  border-radius: 5px;
}
.background-container h4::after {
  content: "";
  position: absolute;
  top: calc(70% + 3px);
  transform: translateY(-50%);
  width: 100%;
  height: 2px;
  background-color: var(--initial-color-3);
  margin-left: 15px;
}
.background-container h4::before {
  content: "";
  position: absolute;
  top: calc(70% + 3px);
  right: calc(50% + 141px);
  transform: translateY(-50%);
  width: 100%;
  height: 2px;
  background-color: var(--initial-color-3);
}
.background-container h4.es::before {
  right: calc(50% + 216px) !important;
}
.background-container h4.esContact::before {
  right: calc(50% + 310px) !important;
}
.about-lagacy {
  padding: 0 5rem;
}
.lagarcy-image {
  width: 100%;
  position: relative;
  height: 450px;
  margin: 5rem 0;
}
.overlay-white-top {
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  background: linear-gradient(to bottom, white, transparent, transparent);
  pointer-events: none;
}
.overlay-white-bottom {
  pointer-events: none;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  background: linear-gradient(to top, white, transparent, transparent);
}
.overlay-white-right {
  pointer-events: none;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  background: linear-gradient(
    to left,
    white,
    transparent,
    transparent,
    transparent,
    transparent,
    transparent
  );
}
.overlay-white-left {
  pointer-events: none;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  background: linear-gradient(
    to right,
    white,
    transparent,
    transparent,
    transparent,
    transparent,
    transparent
  );
}
.overlay-white {
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  z-index: 10;
  background-color: white;
  pointer-events: none;
}
.lagacy-detailes {
  padding: 3rem 5vw 0;
}
.lagacy-detailes-inner {
  margin-top: 4vw;
}
.lagacy-detailes-inner h2 {
  text-align: center;
  color: var(--initial-color);
  font-family: "Times New Roman", Times, serif;
  font-size: 2.5rem;
  margin-bottom: 4vh;
}
.lagacy-detailes-inner .firdst170yesra {
  text-align: center;
}
.firdst170yesra-perent {
  margin-bottom: 3rem !important;
}
.about-lagacy h4 {
  font-family: "Times New Roman", Times, serif;
  font-size: 2rem;
  color: var(--initial-color);
}
.lagacy-text {
  border: 2px solid var(--initial-color-2);
  border-radius: 5px;
  margin-bottom: 1rem;
  padding: 0.5rem;
}
.lagarcy-image {
  overflow: hidden;
}
.lagarcy-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: 1s ease all;
}

.lagacy-detailes-inner-with-left-image {
  display: grid;
  grid-template-columns: 2fr 2fr;
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.lagacy-detailes-image {
  width: 100%;
  min-height: 300px;
  position: relative;
  overflow: hidden;
}
.lagacy-detailes-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
}
.lagacy-detailes-more {
  padding-left: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (max-width: 1250px) {
  .background-container h4 {
    text-shadow: none;
    /* color: var(--initial-color-2); */
    color: white;
    /* -webkit-text-stroke: unset; */
  }
}
@media only screen and (max-width: 991px) {
  .lagacy-detailes-inner-with-left-image {
    display: block;
    margin-bottom: 1rem;
  }
  .lagacy-detailes-more {
    padding: 0%;
  }
}
@media only screen and (max-width: 767px) {
  .about-lagacy {
    padding: 0 0.5rem;
  }
  .background-container {
    height: 150px;
  }
  .background-container h4::after {
    top: calc(70% - 26px);
  }
  .background-container h4::before {
    top: calc(70% - 26px);
  }
  .background-container h4.esContact,
  .background-container h4.es {
    text-align: center;
    font-size: 2rem;
  }
  .background-container h4.esContact::before,
  .background-container h4.esContact::after,
  .background-container h4.es::before,
  .background-container h4.es::after {
    display: none;
  }
  .background-container {
    align-items: center;
  }
  .background-container h4 {
    margin: 0%;
  }
}
/* @media screen and (min-height: 900px) and (orientation: portrait) {
  .mompo-about-hedding-box {
    height: 200px;
  }
  .mompo-about-hedding-box .mompo-about-round {
    height: 200px;
    width: 200px;
  }
  .mompo-about-hedding-box h1 {
    font-size: 95px;
  }
  .mompo-about-hedding-box .tea-leaf-forabout img {
    bottom: -13%;
  }
} */
