.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
:root {
  --initial-color: #ce0e2d;
  --hover-color: #fc5151;
  --initial-color-2: rgba(206, 14, 46, 0.15);
  --initial-color-025: rgba(86, 45, 40, 0.025);
  --initial-color-075: rgba(208, 183, 36, 0.075);
  --initial-color-3: rgba(206, 14, 46, 0.3);
  --initial-color-5: rgba(206, 14, 46, 0.5);
  --hover-color-5: rgba(252, 81, 81, 0.5);
  --initial-light-color: #dcb8bd;
}
