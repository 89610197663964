.productlist-continer {
  padding: 8rem 8rem 6rem;
}
.productlist-main-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.productlist-products {
  padding: 1rem;
  cursor: pointer;
}
.productlist-products-inner {
  /* border: 1px solid #ccc; */
  width: 300px;
  position: relative;
}
.productlist-products-inner-img {
  width: 150px;
  height: 300px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.productlist-products-inner-img img {
  width: 120%;
  height: 120%;
  object-fit: cover;
  object-position: center;
  transition: all 0.5s ease;
}
.productlist-products-inner-img-shadow {
  position: absolute;
  bottom: -3%;
  left: 50%;
  transform: translateX(-49%);
  width: 110px;
  height: 25px;
  background: radial-gradient(#333, transparent, transparent);
  border-radius: 50%;
  z-index: -1;
  transition: all 0.5s ease;
}
.productlist-products-inner-discription {
  padding: 1rem 1rem 1.5rem;
  color: var(--initial-color);
  text-align: center;
}
.productlist-products-inner-discription h4 {
  font-family: "Times New Roman", Times, serif;
  transition: all 0.5s ease;
  margin: 0;
}
.productlist-products-inner-discription p {
  margin: 0;
  transition: all 0.5s ease;
  font-size: 14px;
  color: var(--initial-color-5);
}
.productlist-products-back {
  width: 100%;
  position: absolute;
  bottom: 0%;
  right: 0%;
  left: 0%;
  background: linear-gradient(
    50deg,
    var(--initial-color-3),
    var(--initial-color-075),
    var(--initial-color-025)
  );
  z-index: -1;
  padding: 1rem;
  border-radius: 30px;
  height: 80%;
  opacity: 0.3;
  transition: all 0.5s ease;
  border: 1px solid var(--initial-color-3);
}
.products-headding {
  padding-bottom: 2rem;
}
.products-headding h2 {
  font-family: "Times New Roman", Times, serif;
  font-size: 60px;
  margin-bottom: 0px;
  color: var(--initial-color);
  text-align: center;
}
.products-headding h4 {
  font-family: "SnellBT-Regular";
  font-weight: 200;
  text-transform: none;
  font-size: 35px;
  margin-bottom: 30px;
  text-align: center;
}
.products-headding h4:after {
  content: "";
  display: block;
  height: 2px;
  width: 200px;
  margin-top: 15px;
  margin-right: auto;
  margin-left: auto;
  background-color: var(--initial-color);
}
/* selected start */

.productlist-products.selected .productlist-products-back {
  opacity: 1;
}
.productlist-products.selected .productlist-products-inner-discription h4 {
  color: var(--hover-color);
}
.productlist-products.selected .productlist-products-inner-discription p {
  color: var(--hover-color-5);
}

/* selected end */

/* hovering part in start */

.productlist-products:hover .productlist-products-back {
  opacity: 1;
  height: 75%;
}
.productlist-products:hover .productlist-products-inner-img img {
  margin-bottom: 25px;
}
.productlist-products:hover .productlist-products-inner-discription h4 {
  color: var(--hover-color);
}
.productlist-products:hover .productlist-products-inner-discription p {
  color: var(--hover-color-5);
}
.productlist-products:hover .productlist-products-inner-img-shadow {
  width: 120px;
  height: 35px;
  opacity: 0.4;
  bottom: -4%;
}

/* hovering part in end */
@media only screen and (max-width: 1211px) {
  .products-headding h2 {
    font-size: 40px;
  }
  .products-headding h4 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 991px) {
  .productlist-continer {
    padding: 2rem;
  }
}
@media only screen and (max-width: 767px) {
  .productlist-continer {
    padding: 5rem 0.5rem 3rem;
  }
  .productlist-products {
    width: 50%;
    padding: 0.5rem;
  }

  .productlist-products-inner {
    width: auto;
  }
}
