.mompo-herosection-wrapper {
  height: 48vw;
  position: relative;
  overflow: hidden;
}
.mompo-hero-container {
  height: 100%;
  width: 100%;
}
.overlayofherosection {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 7;
  pointer-events: none;
}
.backgroundwhitecolor {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 6;
  background-color: white;
}
.backgroundwhitecolortop {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 6;
  background-color: white;
}
.overlayofherosection .topcloud {
  width: 100%;
  position: absolute;
  bottom: 0;
}
.overlayofherosection .bottomcloud {
  width: 100%;
  transform: rotate(180deg);
  position: absolute;
  top: 0;
}
.mompo-image-container {
  height: 100%;
}
.mompo-image-container .backgroundheroimage {
  width: 100%;
  height: auto;
  position: absolute;
}
.mompo-bottomwhite-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgb(255, 255, 255),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );

  z-index: 2;
}
.mompo-hero-text-continer {
  position: relative;
  height: 100%;
  margin: 0 15%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mompo-hero-text {
  color: white;
  margin-bottom: 10%;
}
.mompo-hero-h1text {
  font-family: "NocturneSerifTest", serif;
  font-size: 2.8vw;
  color: white;
  margin-bottom: 5px;
  -webkit-text-stroke: 1px var(--initial-color-2);
  text-shadow: -2px 2px 2px var(--initial-light-color);
  line-height: 1.2;
  font-weight: 700 !important;
  text-align: center;
}
.mompo-hero-h1text.spaniol {
  font-size: 2.4vw;
}
.mompo-hero-ptext {
  font-size: 1vw;
  margin-bottom: 80px;
  text-shadow: -1px 1px 1px var(--initial-light-color);
  text-align: center;
  line-height: 1.5;
  color: var(--initial-color);
}
.mompo-cloud-overlay {
  z-index: 2;
}

.mompo-cloud-overlay .solocloud {
  width: 50%;
  position: absolute;
  top: 25%;
  right: -10%;
  z-index: 1;
  height: auto;
  transform: translateY(0px) !important;
}
.mompo-cloud-overlay .solocloud img {
  width: 100%;
}
.mompo-cloud-overlay .solocloud1 img {
  width: 100%;
}
.mompo-cloud-overlay .solocloud1 {
  width: 50%;
  position: absolute;
  top: 20%;
  left: -10%;
  z-index: 4;
  height: auto;
  opacity: 0.7;
}
.mompo-tealeaf-container {
  width: 100%;
  height: 100%;
  z-index: 3;
  position: absolute;
  top: 0;
}
.mompo-leaf {
  height: 100%;
  position: relative;
}
.mompo-leaf .leafblur6 {
  top: 7%;
  left: 30%;
  transform: rotate(20deg);
  width: 70px;
  height: auto;
}
.mompo-leaf .leafblur1 {
  top: 0%;
  right: 26%;
  transform: rotate(45deg);
  width: 75px;
  height: auto;
}
.mompo-leaf .leafblur3 {
  top: 22%;
  left: 47%;
  transform: rotate(-65deg);
  width: 40px;
  height: auto;
}
.mompo-leaf .leafblur4 {
  top: 10%;
  right: 25%;
  width: 40px;
  height: auto;
}
.mompo-leaf .leafgrp3 {
  top: 0%;
  left: 10%;
  width: 250px;
  height: auto;
}
.mompo-leaf .leafgrp31 {
  top: 20%;
  right: 35%;
  width: 140px;
  transform: rotate(100deg);
  height: auto;
}
.mompo-leaf .leafgrp1 {
  bottom: 0%;
  right: 10%;
  width: 200px;
  transform: rotate(-30deg);
  height: auto;
}
.mompo-leaf .leafgrp2 {
  bottom: 2%;
  left: 5%;
  width: 100px;
  transform: rotate(75deg);
  height: auto;
}
.mompo-leaf .leafblur31 {
  bottom: 5%;
  left: 3%;
  width: 8px;
  transform: rotate(75deg);
  height: auto;
}

.mompo-leaf .leafblur2 {
  bottom: 6%;
  left: 15%;
  width: 20px;
  height: auto;
}
.aboutuslead img {
  width: 100%;
}
@media screen and (max-width: 1250px) {
  .mompo-herosection-wrapper {
    height: 85vh;
  }
  .mompo-image-container .backgroundheroimage {
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .mompo-hero-text-continer {
    margin: 0 1rem;
  }
  .mompo-hero-ptext {
    font-size: 1rem;
    margin: 0%;
  }
  .mompo-hero-text-continer {
    align-items: flex-start;
  }
  .mobile-setting-image {
    margin-top: auto;
    bottom: 0%;
  }
  .heroimages-for-all {
    height: 100%;
  }
  .mobilebackgroundhero {
    top: 0% !important;
  }
  .mobile-setting-image.middle {
    height: 75% !important;
    object-position: 25%;
    top: unset !important;
    bottom: 0%;
  }
  .mobile-setting-image.front {
    height: 75% !important;
    object-position: 85%;
    top: unset !important;
    bottom: 0%;
  }
  .mompo-hero-text {
    margin-top: 8rem;
  }
  .mompo-hero-h1text {
    text-shadow: none;
    /* color: var(--initial-color-2); */
    color: white;
    -webkit-text-stroke: 0.5px var(--initial-color-2);
    /* -webkit-text-stroke: unset; */
  }
  .mompo-hero-ptext {
    text-shadow: none;
  }
}
@media only screen and (max-width: 1050px) {
  .mompo-image-container .backgroundheroimage {
    top: 0%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
@media only screen and (max-width: 769px) {
  .mompo-hero-h1text,
  .mompo-hero-h1text.spaniol {
    font-size: 1.5rem;
    text-align: center;
  }
  .mompo-hero-ptext {
    text-align: center;
  }
  .mompo-cloud-overlay .solocloud {
    width: 100%;
    top: 23%;
    right: -43%;
  }
  .mompo-cloud-overlay .solocloud1 {
    width: 100%;
    top: 47%;
    left: -16%;
  }
  .mompo-leaf .leafblur6 {
    left: 6%;
  }
  .mompo-leaf .leafblur3 {
    left: 79%;
  }
  .mompo-leaf .leafblur1 {
    right: 10%;
    width: 20px;
  }
  .mompo-leaf .leafgrp1 {
    width: 185px;
  }
  .mompo-hero-text {
    width: 100%;
    margin-top: 4rem;
  }
  .mompo-leaf .leafblur2 {
    bottom: 2%;
    left: 38%;
  }
  .mompo-image-container .backgroundheroimage {
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .mompo-herosection-wrapper {
    height: calc(85vh - 80px);
  }
  .mompo-hero-text-continer {
    margin: 0 1rem;
  }
  .mompo-hero-h1text br {
    display: none;
  }
  .mompo-hero-ptext {
    font-size: 1rem;
    margin: 0%;
  }
  .mompo-hero-ptext br {
    display: none;
  }
  .mompo-hero-text-continer {
    align-items: flex-start;
  }
  .mobile-setting-image {
    margin-top: auto;
    bottom: 0%;
  }
  .heroimages-for-all {
    height: 100%;
  }
  .mobilebackgroundhero {
    top: 0% !important;
  }
  .mobile-setting-image.middle {
    height: 75% !important;
    object-position: 25%;
    top: unset !important;
    bottom: -5%;
  }
  .mobile-setting-image.front {
    height: 75% !important;
    object-position: 85%;
    top: unset !important;
    bottom: -10%;
  }
}
@media only screen and (max-width: 550px) {
  .overlayofherosection .topcloud {
    width: 450%;
  }
  .overlayofherosection .bottomcloud {
    width: 450%;
  }
}
@media screen and (min-height: 769px) and (orientation: portrait) {
  .mobile-setting-image.front {
    object-position: 85%;
  }
  .mobile-setting-image.middle {
    object-position: 25%;
  }
}
