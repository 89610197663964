.mompo-footer-wrapper {
  height: auto;
  padding: 4rem 2rem;
  position: relative;
  margin-top: 5rem;
}
.footer-container {
  display: grid;
  grid-template-columns: 4.5fr 1fr 4.5fr;
  align-items: center;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}
.footer-copyright p {
  text-align: right;
  margin: 0%;
  font-family: "BernhardModernStdRoman", serif;
  font-size: 18px;
}
.mompo-logo {
  display: flex;
  justify-content: center;
}
.mompo-logo img {
  height: 7rem;
  width: auto;
  padding: 10px 0;
}
.socail-media {
  display: flex;
}
.social-media-inner {
  width: 30px;
  height: 30px;
  color: white;
  font-size: 1rem;
  background-color: var(--initial-color);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.25s ease;
}
.social-media-inner:hover {
  background-color: var(--hover-color);
}

@media only screen and (max-width: 767px) {
  .footer-container {
    display: block;
    position: relative;
  }
  .footer-copyright p {
    text-align: center;
  }
  .socail-media {
    justify-content: center;
  }
  .mompo-footer-wrapper {
    margin-top: 0%;
    padding: 2rem;
  }
}
