.OurUniquness-wrapper {
  height: 900px;
  position: relative;
  margin-top: 3rem;
}
.OurUniquness-container {
  display: flex;
  height: 100%;
}
.textsecond {
  width: 50%;
}
.shape-conaainer {
  position: relative;
  width: 50%;
  height: 900px;
  margin-top: auto;
}
.shape {
  height: 80%;
  position: absolute;
  bottom: 0%;
  left: 0%;
  right: 0%;
  background: linear-gradient(
    20deg,
    var(--initial-color-3),
    var(--initial-color-075),
    var(--initial-color-025)
  );
  border-radius: 370px 0 0 370px;
  margin-bottom: auto;
  z-index: 1;
}
.bottleimage {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 2;
  border-radius: 0 0 0 360px;
  overflow: hidden;
}
.bottleimage .bottoleimage {
  height: 135%;
  width: auto;
  /*   transform: rotate(10deg);
 */
  position: absolute;
  left: -70px;
  top: -5%;
  z-index: 5;
}
.bottleimage .badgeimages {
  height: 150px;
  width: auto;
  position: absolute;
  left: 380px;
  bottom: 300px;
  z-index: 10;
}
.detailes-container {
  position: absolute;
  padding: 0 8rem;
  bottom: 0%;
  height: 900px;
  display: flex;
  align-items: end;
}
.detailes-container-inner {
  height: 80%;
  display: flex;
  align-items: center;
}
.detailes-container h2 {
  font-family: "Times New Roman", Times, serif;
  font-size: 70px;
  margin-bottom: 0px;
  color: var(--initial-color);
}
.detailes-container p {
  font-size: 20px;
  width: 40%;
  margin-bottom: 0%;
  font-family: "BernhardModernStdRoman", serif;
}
.detailes-container p .compneyname {
  font-family: "BernhardModernStdRoman", serif;
}
.detailes-container h4 {
  font-family: "SnellBT-Regular";
  font-weight: 200;
  text-transform: none;
  font-size: 40px;
  margin-bottom: 30px;
}
.detailes-container h4:after {
  content: "";
  display: block;
  height: 2px;
  width: 200px;
  margin-top: 15px;
  background-color: var(--initial-color);
}
.detailes-inner h5 {
  margin-top: 10px;
}
.winefeachers {
  padding-top: 10px;
}
@media only screen and (max-width: 1211px) {
  .detailes-container {
    padding: 0 2rem;
  }
  .detailes-container h2 {
    font-size: 40px;
  }
  .detailes-container h4 {
    font-size: 35px;
  }
}
@media only screen and (max-width: 991px) {
  .detailes-container {
    position: relative;
    padding-top: 4rem;
    height: auto;
  }
  .OurUniquness-container {
    display: block;
  }

  .OurUniquness-wrapper {
    height: auto;
  }
  .detailes-container h2 {
    text-align: center;
  }
  .detailes-container p {
    width: 100%;
    text-align: center;
  }
  .detailes-container h4,
  .detailes-inner h5 {
    text-align: center;
  }
  .detailes-container h4:after {
    margin-left: auto;
    margin-right: auto;
  }
  .shape-conaainer {
    height: 750px;
    width: 75%;
    margin-left: auto;
  }
  .bottleimage {
    border-radius: 0 0 0 300px;
  }
  .bottleimage .badgeimages {
    left: 300px;
  }
}
@media only screen and (max-width: 700px) {
  .shape-conaainer {
    width: 85%;
  }
}
@media only screen and (max-width: 550px) {
  .shape-conaainer {
    width: 100%;
  }
  .detailes-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .shape-conaainer {
    height: 422px;
  }
  .bottleimage .badgeimages {
    height: 90px;
    bottom: 155px;
    left: 200px;
  }
  .bottleimage {
    border-radius: 0 0 0 170px;
  }
  .bottleimage .bottoleimage {
    left: -10px;
  }
}
