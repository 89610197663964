.mompo-404-wrapper {
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.mompo-dilaies-404 {
  height: 100%;
  padding: 0 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mompo-image-box {
  height: 55vh;
  display: flex;
  justify-content: center;
}
.mompo-image-box img {
  height: 100%;
  width: auto;
}
.mompo-text-box {
  text-align: center;
  padding: 0rem 0 2rem;
}
.mompo-text-box h1 {
  color: var(--initial-color);
  text-transform: uppercase;
  font-family: "Times New Roman", Times, serif;
}
@media only screen and (max-width: 550px) {
  .mompo-image-box img {
    width: 100%;
    height: auto;
  }
  .mompo-image-box {
    width: 100%;
  }
  .mompo-404-wrapper {
    padding: 0 3rem;
  }
  .mompo-dilaies-404 {
    padding: 0%;
  }
}
