.howMad-Wrapper {
  position: relative;
  box-sizing: border-box;
  margin-top: 7rem;
  margin-bottom: 5rem;
  overflow-x: hidden;
}
.howMad-grap-tree-img {
  position: inherit;
  width: 70%;
  overflow: hidden;
  height: 700px;
}
.howMad-grap-tree-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 80% 50%;
}
.howMad-grap-perent {
  display: flex;
  position: relative;
  justify-content: end;
}
.howMad-grap-tree-deatiles {
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: 6;
  width: 60%;
  height: 100%;
  padding: 5rem 0 5rem 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.howMad-grap-swizer-deatiles {
  position: absolute;
  top: 0%;
  right: 0%;
  z-index: 6;
  width: 60%;
  height: 100%;
  padding: 5rem 0 5rem 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.howMad-grap-tree-deatiles h2 {
  color: var(--initial-color);
  font-family: "Times New Roman", Times, serif;
  font-size: 40px;
  padding-right: 7rem;
}
.howMad-grap-swizer-deatiles h2 {
  color: var(--initial-color);
  font-family: "Times New Roman", Times, serif;
  font-size: 40px;
  padding-right: 4rem;
}
.howMad-grap-tree-deatiles h4 {
  font-family: "SnellBT-Regular";
  margin-bottom: 30px;
  font-size: 30px;
}
.howMad-grap-swizer-deatiles h4 {
  font-family: "SnellBT-Regular";
  margin-bottom: 30px;
  font-size: 30px;
}
.howMad-grap-tree-deatiles h4:after {
  content: "";
  display: block;
  height: 2px;
  width: 200px;
  margin-top: 15px;
  background-color: var(--initial-color);
}
.howMad-grap-swizer-deatiles h4:after {
  content: "";
  display: block;
  height: 2px;
  width: 200px;
  margin-top: 15px;
  background-color: var(--initial-color);
}
.howMad-grap-tree-deatiles p {
  padding-right: 10rem;
}
.howMad-grap-swizer-deatiles p {
  padding-right: 10rem;
}
.overlay-white-top- {
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  z-index: 5;
  background: linear-gradient(
    to bottom,
    white,
    transparent,
    transparent,
    transparent
  );
}
.overlay-white-left- {
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  z-index: 6;
  background: linear-gradient(
    to right,
    white,
    transparent,
    transparent,
    transparent,
    transparent
  );
}

.howMad-grap-swizer-perent {
  margin-top: 5rem;
  position: relative;
  z-index: 5;
}
.howMad-grap-swizer {
  width: 50%;
  height: auto;
  position: relative;
  overflow: hidden;
}
.howMad-grap-swizer img {
  width: 100%;
  height: auto;
}
.all-grape {
  height: 40px;
  position: absolute;
}
.all-grape img {
  height: 100%;
}
.grape1 {
  top: 25%;
  right: 13%;
  z-index: 5;
}
.grape2 {
  top: 20%;
  right: 12%;
  z-index: 5;
  transform: rotate(-20deg);
}
.grape3 {
  top: 20%;
  right: 15%;
  z-index: 5;
  transform: rotate(-20deg);
}
.berraleimages {
  width: 100%;
  overflow: hidden;
}
.A170YearHistory {
  display: flex;
  justify-content: space-between;
}
.single-iimage-historey {
  width: 33%;
  height: auto;
  position: relative;
  overflow: hidden;
}
.single-iimage-historey img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  filter: grayscale(100%);
  transition: 0.5s all ease;
}
.single-iimage-historey:hover img {
  filter: grayscale(0%);
}
.single-iimage-historey .overlay-white-top {
  background: linear-gradient(
    to bottom,
    white,
    rgba(255, 255, 255, 0.5),
    transparent,
    transparent,
    transparent
  );
}
.single-iimage-historey .overlay-white-bottom {
  background: linear-gradient(
    to top,
    white,
    rgba(255, 255, 255, 0.5),
    transparent,
    transparent,
    transparent
  );
}
.single-iimage-historey .overlay-white-bottom,
.single-iimage-historey .overlay-white-top {
  transition: 0.5s background ease;
}
.single-iimage-historey:hover .overlay-white-top {
  background: linear-gradient(
    to bottom,
    white,
    rgba(255, 255, 255, 0),
    transparent,
    transparent,
    transparent
  );
}
.single-iimage-historey:hover .overlay-white-bottom {
  background: linear-gradient(
    to top,
    white,
    rgba(255, 255, 255, 0),
    transparent,
    transparent,
    transparent
  );
}
.A170YearHistory-headding {
  margin: 4rem 0;
}
.A170YearHistory-headding h2 {
  text-align: center;
  font-family: "Times New Roman", Times, serif;
  font-size: 3rem;
  color: var(--initial-color);
}
.A170YearHistory-headding h4 {
  text-align: center;
  font-family: "SnellBT-Regular";
  font-size: 2rem;
}
.berraleimages-container {
  height: 100vh;
  width: 100%;
  position: "relative" !important;
  z-index: -1;
  overflow: hidden;
}
.berraleimages-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.berraleimages-discription {
  color: white;
  width: 55%;
}
.berraleimages-discription h2 {
  font-family: "Times New Roman", Times, serif;
  font-size: 2.5rem;
}
.berraleimages-discription-perent {
  position: "relative";
  z-index: 1;
  height: 100vh;
  padding: 5rem;
}

.berraleimages-discription.right {
  margin-left: auto;
}
@media only screen and (max-width: 1200px) {
  .berraleimages-discription-perent {
    padding: 2rem;
  }
  .howMad-grap-swizer-deatiles p,
  .howMad-grap-tree-deatiles p {
    padding: 0%;
  }
  .howMad-grap-swizer-deatiles,
  .howMad-grap-tree-deatiles {
    padding: 0 0 0 3rem;
  }
  .howMad-grap-tree-deatiles h2,
  .howMad-grap-swizer-deatiles h2 {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 991px) {
  .A170YearHistory {
    display: block;
  }
  .single-iimage-historey,
  .howMad-grap-tree-img {
    width: 100%;
  }
  .A170YearHistory-headding h2,
  .berraleimages-discription h2 {
    font-size: 2rem;
  }
  .A170YearHistory-headding h4 {
    font-size: 1.5rem;
  }
  .A170YearHistory-headding {
    margin: 4rem 0 1rem 0;
  }
  .berraleimages-discription {
    width: 100%;
    text-align: center;
  }
  .howMad-grap-swizer-deatiles,
  .howMad-grap-tree-deatiles {
    position: relative;
    width: 100%;
    padding: 2rem;
    text-align: center;
  }
  .howMad-grap-perent {
    flex-direction: column;
  }
  .howMad-grap-swizer-deatiles h2,
  .howMad-grap-tree-deatiles h2 {
    font-size: 2rem;
  }
  .howMad-grap-swizer-deatiles h4,
  .howMad-grap-tree-deatiles h4 {
    font-size: 1.5rem;
  }
  .howMad-grap-swizer-deatiles h2,
  .howMad-grap-swizer-deatiles p,
  .howMad-grap-tree-deatiles p,
  .howMad-grap-tree-deatiles h2 {
    padding: 0%;
  }
  .howMad-grap-swizer-deatiles h4:after,
  .howMad-grap-tree-deatiles h4:after {
    margin-left: auto;
    margin-right: auto;
  }
  .howMad-grap-swizer {
    width: 100%;
  }
  .grape1 {
    top: 17%;
    right: 20%;
  }
  .grape2 {
    top: 14%;
    right: 16%;
  }
  .grape3 {
    top: 13%;
    right: 22%;
  }
  .howMad-grap-swizer-perent {
    margin-top: 2rem;
  }
}
@media only screen and (max-width: 767px) {
  .berraleimages-discription-perent {
    height: auto;
    min-height: 100vh;
  }
}
@media only screen and (max-width: 500px) {
  .howMad-grap-swizer-deatiles,
  .howMad-grap-tree-deatiles {
    padding: 1rem;
  }
  .howMad-grap-swizer {
    display: flex;
    justify-content: center;
  }
  .howMad-grap-swizer img {
    height: 100%;
    width: 150% !important;
    object-fit: cover;
    object-position: center;
  }
}
