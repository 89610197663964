.mompo-contect-wrapper {
  position: relative;
  overflow: hidden;
}
.mompo-contect-wrapper .background-container h4::before {
  right: calc(50% + 164px);
}
.mompo-map-headquatar {
  padding: 0 5rem;
}
.map-container {
  margin: 5rem 0;
  height: 450px;
  position: relative;
  width: 100%;
}
.mapwithback {
  width: 100% !important;
  height: 100% !important;
  z-index: 2;
  border: none;
}
.mompo-send-wrapper {
  height: auto;
  padding: 3rem 0 0;
  position: relative;
  overflow: hidden;
}

.mapwithback {
  width: 130vw;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 0;
}

.mompo-sendcontact-box {
  height: 100%;
}
.mompo-sandcontactgrid-box {
  display: block;
  position: relative;
  height: 100%;
  align-items: center;
}
.mompo-sandcontactgrid-box .mompo-side-navigation {
  margin-top: 3vh;
}
.mompo-sandcontactgrid-box .mompo-varticle-line {
  margin-left: 3px;
  margin-bottom: 0.6rem;
  border-left: 1px solid var(--initial-color);
  height: 60px;
}
.mompo-main-sendcontact-sec {
  position: relative;
  grid-column: 2/3;
}
.mompo-main-sendcontact-sec .mompo-big-hedding {
  position: absolute;
  width: 100%;
}
.mompo-main-sendcontact-sec .mompo-big-hedding {
  position: absolute;
  top: -15%;
  right: 0;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
}
.mompo-main-sendcontact-sec .mompo-big-hedding h1 {
  font-size: 15vw;
  color: #a4e373;
  margin-bottom: 5rem;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.mompo-main-sendcontact-heding {
  display: flex;
  justify-content: center;
  margin-top: 4vh;
  margin-bottom: 4vh;
  width: 100%;
}
.mompo-main-sendcontact-heding h1 {
  font-family: "Times New Roman", Times, serif;
  color: var(--initial-color);
  position: relative;
  z-index: 2;
}
.mompo-sendsetailes {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 2;
  padding: 0 3rem;
}
.contactdetailes {
  display: flex;
  flex-wrap: wrap;
  width: 1100px;
  padding: 0 2rem;
  margin-top: 5rem;
}
.contactdetailes-width {
  width: 50%;
  border-top: 2px solid var(--initial-color-2);
  border-left: 2px solid var(--initial-color-2);
  border-right: 2px solid var(--initial-color-2);
  border-bottom: 2px solid var(--initial-color);
  padding: 2rem 0.5rem;
  border-radius: 5px;
}
.contactdetailes h5 {
  text-align: center;
  font-family: "Times New Roman", Times, serif;
  font-size: 2rem;
  color: var(--initial-color);
}
.contactdetailes p {
  text-align: center;
  margin: 0%;
}
.mompo-main-sendcontact-send {
  width: 1100px;
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
}

.inputtag-sendcontect {
  padding: 0.5rem 0.5rem;
  border-top: 2px solid var(--initial-color-2);
  border-left: 2px solid var(--initial-color-2);
  border-right: 2px solid var(--initial-color-2);
  border-bottom: 2px solid var(--initial-color);
  border-radius: 5px;
  margin: 1rem 0;
  color: var(--initial-color);
  background-color: transparent;
  background-color: rgba(255, 255, 255, 0.1);
}
.inputtag-sendcontect:focus {
  outline: none;
}
.mompo-end-inner {
  display: flex;
}
.mompo-end-inner input {
  margin: 1rem 10px;
}
.mompo-send-innner-inner {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.mompo-send-innner-inner label {
  padding: 0 0 0 10px;
}
.mompo-sendcontact-btn {
  border: 2px solid var(--initial-color);
  background-color: var(--initial-color);
  color: white;
  border-radius: 5px;
  margin-left: auto;
  transition: all 0.3s ease;
  padding: 0.5rem 4rem;
}
.mompo-sendcontact-btn:hover {
  background-color: var(--hover-color);
}

.aboutuslead img {
  width: 100%;
}
.suceesmsg {
  width: 100%;
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  border-radius: 0.25rem;
  padding: 0.6rem 1.25rem;
  margin: 0%;
  display: none;
  line-height: 1.2;
}
.unsuccessmsg {
  width: 100%;
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 0.25rem;
  padding: 0.6rem 1.25rem;
  margin: 0%;
  line-height: 1.2;
  display: none;
}
.sendbutton {
  width: 100%;
}
.loddingofbutton {
  width: 100%;
  display: none;
  animation: mymove 2s infinite;
}
@keyframes mymove {
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 1111px) {
  .mompo-sandcontactgrid-box {
    display: block !important;
  }
  .mompo-main-sendcontact-send {
    width: 100%;
    padding: 0%;
  }
  .mompo-end-inner {
    display: block;
  }
  .mompo-send-innner-inner {
    width: 100%;
  }
  .mompo-end-inner input {
    margin: 1rem 0;
  }
  .mompo-sendsetailes {
    padding: 0 1rem;
  }
  .contactdetailes {
    display: block;
    width: 100%;
    padding: 0%;
  }
  .contactdetailes-width {
    width: 100%;
    margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 991px) {
}
@media only screen and (max-width: 880px) {
  .mompo-sendsetailes {
    flex-direction: column;
  }
  .mompo-send-wrapper {
    height: auto;
  }

  .mompo-main-sendcontact-send {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .mompo-map-headquatar {
    padding: 0% 0.5rem;
  }
  .mompo-main-sendcontact-heding h1 {
    padding: 0 1rem;
  }
}

@media only screen and (max-width: 450px) {
}
